.preguntas-frecuentes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .titulo-principal {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
    color:#04082f;
  }
  
  .preguntas-respuestas {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    color:#04082f;
    align-items: center;
  }

  .preguntas-respuestasPrevia{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    flex-direction: row-reverse;
  }
  
  .imagen-container {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    width: 50%;

    .imagen-fondo {
      max-width: 100%; /* Ajusta el tamaño de la imagen */
      height: auto; /* Mantener las proporciones de la imagen */  
      display: block; /* Asegura que la imagen se comporte como un bloque */
    }
  }
  
  


   //Preguntas sobre el proceso de entrega de mi proyecto (PARTE2) (IMAGEN FONDO)

  .imagen-fondoReverse{

    max-width: 100%; /* Ajusta el tamaño de la imagen */
    height: auto; /* Mantener las proporciones de la imagen */  
    display: block; /* Asegura que la imagen se comporte como un bloque */
  }

  //Preguntas sobre el proceso de entrega de mi proyecto (PARTE2)

  .titulo-principalReverse {
    text-align: left;
    font-size: 28px;
    margin-bottom: 30px;
    width: 100%;
    color:#04082f;
  }
  
  .preguntas-desplegables {
    width: 50%;
  }
  
  .pregunta-container {
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    padding: 15px;
    border: 2px solid #dcd2bc;
  }
  
  .pregunta {
    font-size: 18px;
    font-weight: bold;
    color:#04082f;
  }
  
  .respuesta {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-left: 15px;
    padding-left: 0; 
    color:#04082f;
  }
  
  .respuesta.activa {
    max-height: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: none;
    color:#04082f;
  }
  
  .preguntas-invertidas {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  
  .preguntas-column {
    width: 50%;
    padding-right: 20px;
  }
  
  .respuestas-column {
    width: 50%;
    padding-left: 20px;
  }
  
  .pregunta-invertida {
    font-size: 18px;
    font-weight: bold;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .respuesta-invertida {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  

  /* Media query para pantallas pequeñas */
@media (max-width: 768px) {
    .preguntas-respuestas, .preguntas-respuestasPrevia {
      flex-direction: column;
    }
  
    .imagen-container,
    .preguntas-desplegables {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .preguntas-invertidas {
      flex-direction: column;
    }
  
    .preguntas-column,
    .respuestas-column {
      width: 100%;
      padding: 0;
    }
  }
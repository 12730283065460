* {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

/* Contenedor del formulario con fondo */
.form-bg-container {
    width: 100%; /* Asegura que el fondo se extienda completamente */
    background-image: url('Hacemostutesis.png');
    background-size: contain; /* O 'cover' según prefieras */
    background-position: right center; /* Ajusta la posición para que la imagen esté a la derecha */
    background-repeat: no-repeat; /* No repitas la imagen */
    background-color: #ffffff; /* Fondo blanco para el formulario */

    @media (max-width: 900px) {
      background-image: none;

      .form-container {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Centra verticalmente */
        align-items: center; /* Alinea el formulario hacia la izquierda */
        margin-left: 0px;
      }
    }
    

  }
  
  /* FormularioComponent.scss */
  .form-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra verticalmente */
    align-items: flex-start; /* Alinea el formulario hacia la izquierda */
    margin-left: 50px;

    h1,p {
        text-align: center;
        padding-top: 15px;
       color:#04082f;
    }
   
  
    /* Contenedor principal del formulario */
    form {

      max-width: 500px; /* Ancho máximo del formulario */
      padding: 60px; /* Espaciado interno del formulario */
      border: 3px solid #dcd2bc; /* Borde del formulario */
      border-radius: 5px; /* Bordes redondeados */
      background-color: #fff; /* Color de fondo del formulario */
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
      margin-top: 40px;
  
      /* Estilos para etiquetas de campo (placeholders) */
      label {
        transform: translateY(20px);
        transition: all 0.3s;
        pointer-events: none;
        opacity: 0.6;
        &.active {
          transform: translateY(0);
          font-size: 12px;
          opacity: 1;
        }
      }
  
      /* Estilo para campos de entrada de texto y correo electrónico */
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        font-family: 'Montserrat', sans-serif; /* Cambia 'Montserrat' al tipo de letra que prefieras */
        width: 100%; /* Ancho completo */
        padding: 10px; /* Espaciado interno */
        margin-bottom: 15px; /* Espaciado inferior entre campos */
        border: 1px solid #ccc; /* Borde de campo */
        border-radius: 4px; /* Bordes redondeados */
        font-size: 16px; /* Tamaño de fuente */
      }
  
      /* Estilo para el borde rojo al hacer clic en el campo y luego salir sin completarlo */
      input[type="text"]:focus:invalid,
      input[type="email"]:focus:invalid,
      input[type="tel"]:focus:invalid,
      textarea:focus:invalid {
        border: 2px solid red; /* Cambia el color del borde a rojo */
      }
  
      label.error {
        color: red;
        // Otros estilos...
      }
  
      small {
        position: relative;
        bottom: 4px;
      }
  
      /* Estilo para el campo de mensaje */
      textarea {
        max-height: 200px; /* Altura máxima del campo de mensaje */
        resize: vertical; /* Permite redimensionar verticalmente */
      }
  
      /* Estilo para el botón de enviar */
      button {
        background-color: #060d4f; /* Color de fondo del botón */
        color: #fff; /* Color de texto del botón */
        border: none; /* Sin borde */
        padding: 10px 20px; /* Espaciado interno */
        border-radius: 16px; /* Bordes redondeados */
        cursor: pointer; /* Cursor de puntero al pasar el ratón */
        font-size: 18px; /* Tamaño de fuente */
      }
  
      /* Estilo para el botón de enviar al pasar el ratón */
      button:hover {
        background-color: #0056b3; /* Cambia el color de fondo al pasar el ratón */
      }
  
      /* Estilo para mensajes de alerta */
      .alert {
        background-color: #f44336; /* Color de fondo de alerta (rojo) */
        color: #fff; /* Color de texto de alerta (blanco) */
        padding: 10px 20px; /* Espaciado interno */
        border-radius: 4px; /* Bordes redondeados */
        margin-top: 15px; /* Espaciado superior entre alertas y formulario */
        text-align: center; /* Centra el texto */
        display: none; /* Inicialmente oculta la alerta */
      }
  
      /* Estilo para mensajes de éxito */
      .success {
        background-color: #4CAF50; /* Color de fondo de éxito (verde) */
        color: #fff; /* Color de texto de éxito (blanco) */
      }
  
      .success-message {
        text-align: center;
      }
    }


    
  
    @media (max-width: 1270px) {
      form {
        max-width: 300px;
      }
    }

    @media (max-width: 1000px) {
      form {
        max-width: 200px;
      }
    }

    @media (max-width: 800px) {
  
    }

 
  
    @media (max-width: 480px) {
      form {
        max-width: 200px;
  
        button {
          display: block; /* Hacer que el botón sea un bloque para centrarlo */
          margin: 0 auto; /* Centrar horizontalmente */
        }
      }
    }
  }
  
  
   /* Establece la altura fija en pantallas más pequeñas */
  @media (max-height: 540px) {
    .form-bg-container {
      .form-container {
        form {
          margin: 80px ; /* Agrega margen superior e inferior */
          margin-top: calc(80px + 92px);
         
        }
      }
    }
  }
  
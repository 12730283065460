* {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.cartelPublicidad{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: #080E2C;

    svg{
        width: 50px;
        height: 50px;
        color: white;
        margin-left: 10px;
    }
  

    p, a{
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 25px;
        text-decoration: none;
        transition: transform 0.5s ease; /* Añadir transición */
    }

    /* Animaciones para el texto */
    .slide-in {
        transform: translateX(0);
    }

    .slide-out {
        transform: translateX(-100%);
    }
   
}
  
   /* Establece la altura fija en pantallas más pequeñas */
   @media (max-width: 550px) {
    .cartelPublicidad{
        display: flex;
        flex-direction: column;
        height: 160px;
        svg{
            width: 35px;
            height: 35px;
        }
      p, a{
        font-size: 20px;
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }
.footer {
    padding: 20px;
    background-color: #ffffff;
    color: #04082f;

    .lineaUp {
        width: 100%;
        background-color: #060d4f;
        height: 8px;
        display: block;
        margin-bottom: 25px;
    }

    .footer-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 20px;

        .logo-container {
            .footer-logo {
                width: 200px; // Ajusta el tamaño según tus necesidades
            }
        }

        .social-icons {
            display: flex;

            .icon-container {
                background-color: #f0f0f0; // Color de fondo para los iconos
                border-radius: 50%; // Border radius al 50%
                padding: 10px; // Espaciado interno
                display: flex; // Centrar el SVG
                align-items: center; // Centrar verticalmente
                justify-content: center; // Centrar horizontalmente
                margin-left: 15px; // Margen entre iconos

                svg {
                    width: 30px; // Ajusta el tamaño de los iconos
                    height: 30px;
                }
            }
        }
    }

    .footer-links {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        a {
            margin: 0 10px;
            text-decoration: none;
            color: #04082f;
            text-align: center;
        }
    }

    .legal-info {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        span {
            margin: 0 10px;
            color: #04082f;

            &.separator {
                margin: 0 5px; /* Espacio adicional para la línea de separación */
            }
        }

        a {
            text-decoration: none;
            color: #04082f;
            text-align: center;
        }
    }

    .footer-bottom {
        text-align: center;
        margin-top: 30px;

        p {
            margin: 0;
            color: #04082f;
        }
    }
}

   /* Establece la altura fija en pantallas más pequeñas */
   @media (max-width: 650px) {
    .footer{
        .footer-content {
            flex-direction: column;
        }
    }
  }

* {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: white;
    position: relative;
    z-index: 100;

    .contenedor-logo {
        display: flex;
        align-items: center;

        .logo {
            height: 70px;
            margin-left: 20px;
        }
    }

    .navbar {
        display: flex;
        /* Mostrar la barra de navegación horizontal en pantallas grandes */
        gap: 20px;

        a {
            font-size: 18px;
            color: #04082f;
            font-weight: 500;
            padding: 10px;
            position: relative;
        }

        &.open {
            display: flex;
            flex-direction: column;
            /* Mostrar la barra de navegación verticalmente en pantallas pequeñas */
            position: absolute;
            top: 80px;
            left: 0;
            width: 100%;
            background-color: #ffffff;
            text-align: center;
            padding: 20px 0;
        }
    }

    .hamburger {
        display: none;
        /* Ocultar el botón de hamburguesa en pantallas grandes */
        flex-direction: column;
        gap: 5px;
        cursor: pointer;

        .line {
            width: 30px;
            height: 3px;
            background-color: #333;
            transition: transform 0.3s ease, opacity 0.3s ease;
        }

        &.open .line:nth-child(1) {
            transform: rotate(45deg) translate(5px, 5px);
        }

        &.open .line:nth-child(2) {
            opacity: 0;
        }

        &.open .line:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -5px);
        }
    }

    .btn button {
        font-weight: 700;
        color: white;
        padding: 9px 25px;
        background-color: #060d4f;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 20px;
    }
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    .header {
        .navbar {
            display: none;
            /* Ocultar la barra de navegación por defecto en pantallas pequeñas */
        }

        .hamburger {
            display: flex;
            /* Mostrar el botón de hamburguesa en pantallas pequeñas */
        }

        .navbar.open {
            display: flex;
            /* Mostrar el menú cuando la clase `open` esté activa */
        }

        .navbar a.selected {
            color: #ffffff;
            /* Color del texto */
            background-color: #060d4f;
            /* Fondo azul claro */
            border-radius: 5px;
            /* Para un poco de curvatura */
        }
        
    }

}
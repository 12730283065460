.ayuda-section {
    padding: 20px;
    text-align: center;

    .lineaSpan{
        width: 100%;
        background-color: #060d4f;
        height: 8px;
        display: block;
        margin-bottom: 25px;
    }


    
  
    .text-container {
      margin-bottom: 20px;
      
  
      h1 {
        font-size: 3rem;
        color:#04082f;
        margin-top: 25px;
        text-align: center;
      }
  
      p {
        font-size: 1.8rem;
        color:#04082f;
        line-height: 1.6;
        margin-top: 10px;
        text-align: center;
      }

      a{
        text-decoration: none;
        margin-top: 5px;
     
      }

      .contenedor-linkWsp {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color:#04082f;
          transition: color 0.3s ease;

      
          svg {
            width: 50px; /* Tamaño del ícono */
            height: 50px;
            margin-right: 10px; /* Espacio entre el ícono y el texto */
            color: #25D366; /* Color verde de WhatsApp */
          }
      
          p {
            font-size: 1.4rem;
            margin: 0;
            color:#04082f;
          }
      
          &:hover {
            color: #0094ea; /* Cambiar el color al pasar el mouse */
          }
        }
      }
    }
  
    .counter-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
     
  
      .counter-item {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        h2 {
          font-size: 2.5rem;
          color:#04082f;
          margin: 0;
          text-align: center;
        }
  
        span {
          font-size: 1rem;
          color:#04082f;
          text-align: center;
        }
      }
    }
  }


  @media (max-width: 650px) {
    .counter-container {
        flex-direction: column; /* Cambia a columna en pantallas pequeñas */
        align-items: center; /* Centra los elementos en pantallas pequeñas */

        .counter-item{
            margin-bottom: 25px;
        }
    }
    
}


